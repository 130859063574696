
import { Component, Vue } from 'vue-property-decorator'
import { LostTable } from '@/types/lostGoods.d'
@Component

export default class RestaurantDetail extends Vue {
  private info: LostTable | {}= {}

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get<LostTable>(this.$apis.lostGoods.selectLostGoodsById, {
      id: this.$route.params.id
    }).then(res => {
      this.info = res || {}
    })
  }
}
